"use client";
import counselingWelcomeSVG from "assets/counseling/welcome.svg";
import { Panel, TwoColumn, UserDetailsCard } from "components";
import { useOrder } from "store";
import { FormattedMessage, useIntl } from "services";
import scss from "./WelcomePage.module.scss";
import styled from "@emotion/styled";
import { H1 } from "components/common/Typography/typography";
import { Typography } from "@mui/material";
import { PageWrapper } from "components/common/PageWrapper";
import { CounselingButton } from "./components/CounselingButton";
import { useCounselingSubmit } from "hooks";
import { useGetOrderByIdQuery } from "__generated__/types";

const UserDetails = styled.div({
  width: "75%",
  minWidth: "30rem",
});

export function WelcomePage() {
  const { formatMessage } = useIntl();
  const order = useOrder();
  const { customer, entitlement } = order;
  const { data: orderData, loading: orderLoading } = useGetOrderByIdQuery({
    variables: { id: order.id! },
    skip: !order.id,
    fetchPolicy: "cache-and-network",
  });

  const { handleNext, loading } = useCounselingSubmit({
    nextStatus: order.hasEntitlementsCounseling
      ? "ENTITLEMENTS_PREFERENCES"
      : "ADDRESSES_ORIGIN",
  });

  const hasMultipleShipments =
    orderData?.order?.moveTaskOrder?.shipments &&
    orderData?.order?.moveTaskOrder?.shipments.length > 1;
  const hasCompletedCounselingForOtherShipment =
    orderData?.order?.moveTaskOrder?.shipments.some(
      (shipment) => shipment.counselingStatus === "COMPLETE"
    );

  const messageCopy = () => {
    if (hasMultipleShipments) {
      if (hasCompletedCounselingForOtherShipment) {
        return "counseling.welcome.instructions.remaining-shipment";
      }
      return "counseling.welcome.instructions.multiple-shipments";
    }
    return "counseling.welcome.instructions";
  };

  return (
    <PageWrapper>
      <TwoColumn>
        <div>
          <H1>
            <FormattedMessage id="counseling.welcome.preMoveWelcome" />
          </H1>
          {!orderLoading ? (
            <Typography variant="mBody" component="p" marginBottom={"32px"}>
              <FormattedMessage
                id={messageCopy()}
                values={{ strong: (str: string) => <strong>{str}</strong> }}
              />
            </Typography>
          ) : null}
          <UserDetails>
            <Panel
              className={scss.panel}
              ariaLabel={formatMessage({ id: "ariaLabels.customer.info" })}
            >
              <UserDetailsCard customer={customer} entitlement={entitlement} />
            </Panel>
            <CounselingButton onClick={handleNext} isSubmitting={loading} />
          </UserDetails>
        </div>
        <div>
          <img
            src={counselingWelcomeSVG.src}
            alt={formatMessage({ id: "imageAlt.accent.counseling.welcome" })}
          />
        </div>
      </TwoColumn>
    </PageWrapper>
  );
}
