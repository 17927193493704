import { ReactNode } from "react";
import { useIntl } from "services";
import { useCrewTracker } from "services/crewTracker";
import { CrewTracker } from "models";
import { formatDate, formatTime } from "@movehq/datetime";
import styled from "@emotion/styled";
import { Box, Divider as MuiDivider, Typography } from "@mui/material";
import {
  MoveTrackerKey,
  MoveTrackerStep,
  Service,
  ServiceStatus,
} from "__generated__/types";
import { Step } from "hooks/useMoveTrackerSteps";

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`;

const Divider = styled(MuiDivider)`
  margin: ${({ theme }) => `${theme.spacing(3)} 0`};
`;

export function StatusInfo({
  title,
  value,
}: {
  title: string;
  value: string | ReactNode;
}) {
  return (
    <div>
      <Typography variant="xxsHeading" component="h4" color="#666666">
        {title}
      </Typography>
      <div>
        <Typography variant="mHeading" component="p" color="#01314a">
          {value}
        </Typography>
      </div>
    </div>
  );
}

const shouldShowEstimatedArrival = (stepKey: MoveTrackerKey) => {
  switch (stepKey) {
    case MoveTrackerKey.Pack:
    case MoveTrackerKey.Load:
    case MoveTrackerKey.Delivery:
      return true;
    default:
      return false;
  }
};

function getEstimatedArrival(
  currentService: Service,
  crewTracker?: CrewTracker
) {
  if (!crewTracker) {
    return currentService.status === ServiceStatus.InProgress
      ? "Arrived"
      : "TBD";
  }
  // If the current service has a time zone, convert estimatedArrivalTime returned from maps api to that timezone
  // Otherwise, we fallback to displaying estimatedArrivalTime in the user's local timezone

  let crewArrivalTime = crewTracker.estimatedArrivalTime;
  const timeZone = currentService.plannedStartDatetimeZone;
  if (timeZone) {
    crewArrivalTime = crewTracker.estimatedArrivalTime.withTimeZone(timeZone);
  }

  return (
    <time
      dateTime={crewArrivalTime.toString()}
      title={crewArrivalTime.toString()}
    >
      {formatTime(crewArrivalTime, "shortTimeZone")}
    </time>
  );
}

function getTitleForActivityDetail(stepKey?: MoveTrackerKey) {
  switch (stepKey) {
    case MoveTrackerKey.Pack:
      return "Packing Scheduled";
    case MoveTrackerKey.Load:
      return "Loading Scheduled";
    default:
      return "Delivery Scheduled";
  }
}

function getDateSpread(steps: Step[], currentStep: Step | null) {
  const deliveryStep = steps?.find((step) =>
    step
      ? [MoveTrackerKey.Delivery, MoveTrackerKey.FinalDelivery].includes(
          step?.key
        )
      : undefined
  );

  const useCurrentStepPlannedDate =
    currentStep &&
    [MoveTrackerKey.Pack, MoveTrackerKey.Load].includes(currentStep.key);

  const stepToUse = useCurrentStepPlannedDate ? currentStep : deliveryStep;

  if (!stepToUse) {
    return "TBD";
  }

  const startDate = stepToUse.service?.plannedStartDatetime
    ? formatDate(stepToUse.service?.plannedStartDatetime, "twoDigitDayAndMonth")
    : "TBD";
  const endDate = stepToUse.service?.plannedEndDatetime
    ? formatDate(stepToUse.service?.plannedEndDatetime, "twoDigitDayAndMonth")
    : undefined;

  return endDate && endDate !== startDate
    ? `${startDate} - ${endDate}`
    : startDate;
}

export function StatusDetails({
  steps,
  currentStep,
}: {
  steps: Step[];
  currentStep: Step | null;
}) {
  const { crewTracker } = useCrewTracker();
  const { formatMessage } = useIntl();
  const activityTitle = getTitleForActivityDetail(currentStep?.key);
  const estimatedArrival =
    currentStep?.service &&
    getEstimatedArrival(currentStep.service, crewTracker);
  const dateSpread = getDateSpread(steps, currentStep);

  return (
    <Box mb="16px">
      <Divider />
      <Container
        aria-details={formatMessage({
          id: "ariaLabels.trackYourMove.statusDetails",
        })}
        data-testid="statusDetails"
      >
        {activityTitle && (
          <StatusInfo title={activityTitle} value={dateSpread} />
        )}
        {currentStep && shouldShowEstimatedArrival(currentStep.key) && (
          <StatusInfo title="Estimated Arrival" value={estimatedArrival} />
        )}
      </Container>
    </Box>
  );
}
